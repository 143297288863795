.photo-upload-page {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

/* Container principal */
.photo-container {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Mantém o botão de envio fixo no topo */
.sticky-header {
  position: sticky;
  top: 0;
  background: white;
  padding: 10px;
  z-index: 1000;
  text-align: center;
  border-bottom: 2px solid #ccc;
}

/* Grupos de formulário */
.form-group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 20px;
}

/* Dropdown de seleção */
.dropdown {
  width: 100%;
  padding: 0.75rem;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

/* Estilização para o input de arquivos */
.custom-file-input {
  width: 100%;
  padding: 0.75rem;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

/* Botão de envio */
.upload-button {
  margin-top: 1rem;
  padding: 0.75rem 1.5rem;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

.upload-button:hover {
  background-color: #0056b3;
}

/* Área de Drag & Drop */
.drag-drop-area {
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
  margin: 10px 0;
  cursor: pointer;
  transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  border-radius: 8px;
  background: #fafafa;
}

.drag-drop-area.dragging {
  border-color: #00bfff;
  background-color: #f0f8ff;
}

/* Botão de seleção do DatePicker */
.datepicker-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-weight: bold;
}

.datepicker-button:hover {
  background-color: #0056b3;
}

/* Mensagens de erro e sucesso */
.error, .success {
  margin-top: 10px;
  padding: 8px;
  border-radius: 5px;
  text-align: center;
  font-weight: bold;
}

.error {
  color: #d9534f;
  background: #f8d7da;
  border: 1px solid #f5c6cb;
}

.success {
  color: #155724;
  background: #d4edda;
  border: 1px solid #c3e6cb;
}

/* Mensagem de fotos detectadas */
.photo-info {
  margin-top: 10px;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  background: #eef6ff;
  border: 1px solid #b3d4ff;
  border-radius: 5px;
  text-align: center;
}

.photo-preview {
  margin-top: 10px;
  text-align: center;
}

.preview-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.photo-item {
  border: 1px solid #ddd;
  padding: 5px;
  border-radius: 5px;
  background: white;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}

.photo-item img {
  max-width: 100px;
  max-height: 100px;
  border-radius: 5px;
}
